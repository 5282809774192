import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Section from '../Section';

import styles from './Teams.module.css';

const Teams = ({ teams }) => (
  <Section className={styles.Teams} neutral>
    {teams.map(team => (
      <div key={team.path} className={styles.Team}>
        <Link style={{ boxShadow: 'none' }} to={team.path}>
          <h3>{team.name}</h3>
          <div className={styles.ImageWrapper}>
            {team.images.length > 0 ? (
              <div
                className={styles.Image}
                style={{ backgroundImage: `url(${team.images[0].publicURL})` }}
              />
            ) : (
              <div className={styles.NoImage}> ? </div>
            )}
          </div>
        </Link>
        <p dangerouslySetInnerHTML={{ __html: team.excerpt }} />
      </div>
    ))}
  </Section>
);

Teams.propTypes = {
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      excerpt: PropTypes.string,
      name: PropTypes.string,
      path: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({ publicURL: PropTypes.string, name: PropTypes.string })
      ),
    })
  ),
};

export default Teams;
