import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Header from '../../components/Header';
import TeamsSection from '../../components/sections/Teams';
import Layout from '../../components/Layout';

const Teams = ({ data }) => {
  const siteTitle = get(data, 'site.siteMetadata.title');
  const nodes = get(data, 'allMarkdownRemark.edges');
  const teams = nodes.map(({ node }) => ({
    name: get(node, 'frontmatter.title') || node.frontmatter.path,
    images: get(node, 'frontmatter.teamImage') || [],
    path: node.frontmatter.path,
  }));

  return (
    <Layout>
      <Header title={'Unsere Mannschaften'} />
      <Helmet title={`Mannschaften :: ${siteTitle}`} />

      <TeamsSection teams={teams} />
    </Layout>
  );
};

Teams.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    allMarkdownRemark: PropTypes.object,
  }),
};

export default Teams;

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { frontmatter: { path: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            path
            teamImage {
              publicURL
              name
            }
          }
        }
      }
    }
  }
`;
